import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import React, { Component } from "react"
import Fade from "react-reveal/Fade"
import "../assets/popup.css"
import StudySlider from "../components/StudySlider"
import Testimonial from "../components/Testimonial/Testimonial"
import SeoTitle from "../components/Title/SeoTitle"

class Subscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showSuccessPopup: false,
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      description: "",
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      description: "",
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const { name, email, phoneNumber, location, description } = this.state

    try {
      const response = await fetch("http://localhost:8080/api/v1/inquiries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          location,
          inquiryDescription: description,
        }),
      })

      if (response.ok) {
        // Handle success
        console.log("Inquiry submitted successfully")
        // Reset form
        this.setState({
          showSuccessPopup: true,
          showModal: false,
          name: "",
          email: "",
          phoneNumber: "",
          location: "",
          description: "",
        })
        setTimeout(() => {
          this.setState({ showSuccessPopup: false })
        }, 3000)
      } else {
        // Handle error
        console.error("Failed to submit inquiry")
      }
    } catch (error) {
      console.error("Error submitting inquiry:", error)
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true })
  }

  render() {
    let FooterData = this.props.FooterData
    return (
      <React.Fragment>
        <section id="offeringsSection" className="seo_fact_area sec_pad">
          <div className="container">
            <SeoTitle
              Title="Our Offerings"
              TitleP="Find out about our offerings and how we can help you succeed in your studies."
            />
          </div>
        </section>
        <section id="servicesSection" className="case_studies_area sec_pad">
          <div className="container">
            <StudySlider />
          </div>
        </section>
        <Testimonial tClass="testimonial_area sec_pad" FooterData={FooterData} />
        <section className="seo_partner_logo_area sec_pad">
          <div className="container">
            <div className="seo_sec_title text-center mb_70">
              <Fade bottom>
                <h2>
                  Uniting with educators, institutions, and organizations
                  <br /> to shape the future of education and unleash potential.
                  <br /> Meet our partners
                </h2>
              </Fade>
            </div>
            <div className="partner_logo_area_four">
              <div className="row partner_info">
                <div className="logo_item">
                  <a href=".#">
                    <img
                      src={require("../img/seo/freedom.png")}
                      alt=""
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>
                <div className="logo_item">
                  <a href=".#">
                    <img
                      src={require("../img/seo/citypolar.jpg")}
                      alt=""
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>
                {/* Other logo items */}
              </div>
            </div>
          </div>
        </section>

        <section className="seo_call_to_action_area sec_pad">
          <div className="container">
            <div className="seo_call_action_text">
              <h2>
                Ready to get started?
                <br /> It's fast, and very easy!
              </h2>
              <Button
                onClick={this.handleOpenModal}
                variant="contained"
                color="primary"
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  width: "200px",
                  height: "50px",
                }}
              >
                Get a Quote
              </Button>
            </div>
          </div>
        </section>
        <Modal open={this.state.showModal} onClose={this.handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography sx={{ textAlign: "center", fontSize: 30 }}>Inquiry Form</Typography>
            <div style={{ overflowY: "auto", maxHeight: "calc(70vh - 100px)" }}>
              <form onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label="Your Name"
                    autoComplete="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email address"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    required
                    fullWidth
                    id="PhoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    autoComplete="Phone Number"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    required
                    fullWidth
                    id="location"
                    name="location"
                    label="Location"
                    autoComplete="location"
                    value={this.state.location}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    required
                    fullWidth
                    id="description"
                    name="description"
                    label="Inquiry description"
                    autoComplete="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "8px", marginBottom: "8px" }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </div>
          </Box>
        </Modal>
        {this.state.showSuccessPopup && (
          <div className="success-popup">Your inquiry has been received.</div>
        )}
      </React.Fragment>
    )
  }
}

export default Subscribe
