import { Button } from "@mui/material"
import Grid from "@mui/material/Grid"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}
const buttonStyle = {
  display: "block",
  margin: "auto",
  mt: 4,
}

export default function BasicModal({ title, content }) {
  const [open, setOpen] = React.useState(false)

  const HandleOpen = () => setOpen(!open)
  return (
    <div>
      <Button onClick={HandleOpen}>Read More</Button>
      <Modal
        open={open}
        onClose={HandleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            {content}
          </Typography>
          <Button sx={buttonStyle} onClick={HandleOpen}>
            Close
          </Button>
        </Grid>
      </Modal>
    </div>
  )
}
