const FooterData = {
  CompanyWidget: [
    {
      id: 1,
      title: "Get In Touch",
      description:
        "Subscribe to the CRACKiT mailing list to get the most up to date information about us!",
    },
  ],
  AboutWidget: [
    {
      id: 1,
      title: "About Us",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "Company",
        },
        {
          id: 2,
          url: "https://oms.crackit.co.bw/",
          text: "CRACKiT Online",
        },
      ],
    },
    /* {
      id: 2,
      title: "Help",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "FAQ",
        },
        {
          id: 2,
          url: "#",
          text: "Term & conditions",
        },
        {
          id: 3,
          url: "#",
          text: "Reporting",
        },
        {
          id: 4,
          url: "#",
          text: "Documentation",
        },
        {
          id: 5,
          url: "#",
          text: "Support Policy",
        },
        {
          id: 6,
          url: "#",
          text: "Privacy",
        },
      ],
    }, */
  ],
  /*   termsCondition: [
    {
      id: 1,
      title: "Team Solutions",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "Support",
        },
        {
          id: 2,
          url: "#",
          text: "Sales",
        },
        {
          id: 3,
          url: "#",
          text: "Report Abuse",
        },
        {
          id: 4,
          url: "#",
          text: "System Status",
        },
      ],
    },
  ], */
  SocialLinks: [
    {
      id: 1,
      title: "Social Links",
      menuItems: [
        {
          id: 1,
          url: "#",
          icon: "ti-facebook",
        },
        {
          id: 2,
          url: "#",
          icon: "ti-twitter-alt",
        },
        {
          id: 3,
          url: "#",
          icon: "ti-instagram",
        },
        {
          id: 4,
          url: "#",
          icon: "ti-youtube",
        },
      ],
    },
  ],
  socialIcon: [
    {
      id: 1,
      url: "https://www.facebook.com/",
      icon: "ti-facebook",
    },
    {
      id: 2,
      url: "#",
      icon: "ti-twitter-alt",
    },
    {
      id: 3,
      url: "#",
      icon: "ti-vimeo-alt",
    },
    {
      id: 4,
      url: "#",
      icon: "ti-pinterest",
    },
  ],
  copywrite: "© CRACKiT (Pty) Ltd 2024 All rights reserved.",
  TestimonialText: [
    {
      id: 1,
      image: "new/test1.jpeg",
      description:
        "I was introduced to Orapa CRACKiT tuition by a schoolmate. I began lessons on April 25, 2021. The tutors consistently helped me with my schoolwork and clarified concepts I didn’t understand. Thanks to their support, motivation, and care, I saw steady improvement, ultimately achieving an A in my JCE final examinations.",
      authorName: "Amutjilani Godwill Libinda",
      authorPost: "Past JCE Student",
    },
    {
      id: 2,
      image: "new/test2.jpeg",
      description:
        "I joined Orapa CRACKiT in January 2021. Initially skeptical about tutors, Orapa CRACKiT proved me wrong by providing excellent educational support, skills, and emotional preparation. My success was due to teamwork, revision papers, weekly quizzes, and monthly tests. I am grateful and honored to have been part of this great family.",

      authorName: "Keagakwa Kaone Anneleng",
      authorPost: "Past JCE Student",
    },
    {
      id: 3,
      image: "new/test3.jpeg",
      description:
        "I joined Orapa CRACKiT tuition in early 2021, initially reluctant about having a tutor. However, their dedication and support changed my mind. The CRACKiT tutors were mentors, friends, and motivators who helped me excel through active recall, past papers, quizzes, and monthly tests. They believed in me and gave me hope, which led to my success",
      authorName: "Lone Jessica Mongakgotla",
      authorPost: "Past JCE Student",
    },
  ],
  aboutLinks: [
    {
      id: 1,
      url: "#",
      item: "Developer",
    },
    {
      id: 2,
      url: "#",
      item: "Blog",
    },
    {
      id: 3,
      url: "#",
      item: "Investor",
    },
    {
      id: 4,
      url: "#",
      item: "Sitemap",
    },
    {
      id: 5,
      url: "#",
      item: "Jobs",
    },
  ],
  helpLinks: [
    {
      id: 1,
      url: "#",
      item: "Help and Contact",
    },
    {
      id: 2,
      url: "#",
      item: "Fees",
    },
    {
      id: 3,
      url: "#",
      item: "Security",
    },
    {
      id: 4,
      url: "#",
      item: "App",
    },
    {
      id: 5,
      url: "#",
      item: "Shop",
    },
  ],
  PrivacyLinks: [
    {
      id: 1,
      url: "#",
      item: "Privacy Policy",
    },
    {
      id: 2,
      url: "#",
      item: "Legal Agreement",
    },
    {
      id: 3,
      url: "#",
      item: "Feedback",
    },
  ],
  about: [
    {
      id: 1,
      url: "#",
      text: "Company",
    },
    {
      id: 2,
      url: "#",
      text: "Leadership",
    },
    {
      id: 3,
      url: "#",
      text: "Diversity",
    },
    {
      id: 4,
      url: "#",
      text: "Jobs",
    },
    {
      id: 5,
      url: "#",
      text: "Press",
    },
    {
      id: 6,
      url: "#",
      text: "Wavelength",
    },
  ],
  Solution: [
    {
      id: 1,
      url: "#",
      text: "Project Management",
    },
    {
      id: 2,
      url: "#",
      text: "Agile",
    },
    {
      id: 3,
      url: "#",
      text: "Task Management",
    },
    {
      id: 4,
      url: "#",
      text: "Reporting",
    },
    {
      id: 5,
      url: "#",
      text: "Work Tracking",
    },
    {
      id: 6,
      url: "#",
      text: "See All Uses",
    },
  ],
  teamSolution: [
    {
      id: 1,
      url: "#",
      text: "Engineering",
    },
    {
      id: 2,
      url: "#",
      text: "Designers",
    },
    {
      id: 3,
      url: "#",
      text: "Sales",
    },
    {
      id: 4,
      url: "#",
      text: "Developers",
    },
    {
      id: 5,
      url: "#",
      text: "Marketing",
    },
    {
      id: 6,
      url: "#",
      text: "See All team",
    },
  ],
}
export default FooterData
