import React from "react"
import Fade from "react-reveal/Fade"
import SeoTitle from "../Title/SeoTitle"

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad" id="learnmore">
        <div className="container">
          <div className="mb_70">
            <SeoTitle
              Title="One team, one mission, unleashing potential!"
              TitleP="Re ruta, go utwala!"
            />
          </div>{" "}
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon1.png")} alt="" />
                  <h4>Learn Conveniently</h4>
                  <p>
                    At CRACKiT Botswana, we value your time, we allow you to learn your at your own
                    time and at the comfort of your home with home and online tuition.
                  </p>
                  {/* <a href=".#"><i className="arrow_right"></i></a> */}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon2.png")} alt="" />
                  <h4>Expert Tutors</h4>
                  <p>
                    All our tutors are qualified with a minimum of a diploma from a recognizable
                    tertiary instuition in Botswana and from other countries. All are tutors are a
                    periodically on teaching skills.
                  </p>
                  {/* <a href=".#"><i className="arrow_right"></i></a> */}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon5.png")} alt="" />
                  <h4>Quick & Reliable</h4>
                  <p>
                    Find your nearest tuition center to help you succeed in your studies. We help
                    you find the nearest tutor or tuition center within your area of residence in
                    Botswana.
                  </p>
                  {/* <a href=".#"><i className="arrow_right"></i></a> */}
                </div>
              </div>
            </Fade>
            <div className="col-lg-12 text-center mt_40">
              <a href="#offeringsSection" className="seo_btn seo_btn_one btn_hover">
                All Offerings
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/2.jpeg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>Get tips & tricks on how to skyrocket your grades</h2>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <img src={require("../../img/seo/icon4.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>We have extra-curricular activities</h3>
                      <p>
                        Our extra-curricular activities include swimming, chess, computer lessons,
                        and educational visits.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <img src={require("../../img/seo/icon3.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Personal development</h3>
                      <p>We focus on developing students to become independent when learning.</p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/3.jpeg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    CRACKiT Tuition admits the following types of students
                  </h2>
                  <p className="wow fadeInUp">
                    <li>
                      Supplementing students or Rewriting: Form 3 (JCE) , Form 4 (IGCSE) and form 5
                      (BGCSE)
                    </li>
                    <li>Completing students (first time final exam sitters)</li>
                    <li>Continuing students (those who are not sitting for their final exams)</li>
                    <li>Skipping students (after passing a rigorous assessment)</li>
                  </p>
                  <p className="wow fadeInUp">
                    <strong>NB:</strong> Form 3s must do a minimum of 7 subjects and maximum of 9
                    subjects. Additionally, CRACKiT does not offer tuition for practical subjects
                    (Physical education, Art, Music, HE, and D&T).
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Service
