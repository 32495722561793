import React, { Component, useState } from "react"
//import "../assets/slider.css"
import Slider from "react-slick/"
import BasicModal from "./BasicModal"

const OfferingsComponent = ({ imgSrc, title, content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <button className="subject-button" onClick={handleButtonClick}>
        <div className="iitem">
          <div className="studies_item">
            <img src={require(`../img/seo/${imgSrc}`)} alt="" />
            <div className="text">
              <h4>{title}</h4>
              <BasicModal
                open={isModalOpen}
                onClose={handleCloseModal}
                title={title}
                content={content}
              />
            </div>
          </div>
        </div>
      </button>
    </>
  )
}

class StudySlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <React.Fragment>
        <div id="offerings">
          <Slider {...settings} className="case_studies_slider">
            <OfferingsComponent
              imgSrc="o1.jpeg"
              title="Preschool (2-5 years old)"
              content="Our preschool introduces students to the world of playing and learning. We start teaching them the English language, then Mathematics, Setswana and French/Chinese."
            />
            <OfferingsComponent
              imgSrc="o2.jpeg"
              title="Lower Primary (STD1-4)"
              content="Lower primary education lays down the foundation for learning and introduces pupils to elementary topics for all subjects. It is for this reason that pupils need to be grounded thoroughly, especially in languages and arithmetic."
            />
            <OfferingsComponent
              imgSrc="o3.jpeg"
              title="Upper Primary (STD5-7)"
              content="Upper primary education is the highest primary education and prepares learners for the secondary school level. Transitioning well at this stage is very important as it will help the confidence of learners as they progress in their learning. At this stage we help learners in all subjects’ areas and ensure that they are grounded in the understanding of all topics. We focus on preparing students for Primary School Leaving Examinations (PSLE) in the following subjects; Mathematics, Science, English, Setswana, Agriculture, Social Studies, Religious and Moral Education."
            />
            <OfferingsComponent
              imgSrc="o4.png"
              title="JCE (Form 1-3)"
              content="Considering this is also the stage of self-discovery we offer tutorials on all subjects as well as counselling and mentoring services to help learners deal with the challenges they face as teenagers. We prepare students for Junior Certificate Examinations (JCE) in the following subjects; Mathematics, English, Setswana, Science, Agriculture, Social Studies, Moral Education, Business Studies (Commerce & Office Procedures/Accounting) and Religious Education."
            />
            <OfferingsComponent
              imgSrc="o5.jpeg"
              title="BGCSE (Form 4-5) & IGCSE (Form 1-5)"
              content="The senior secondary level marks the deciding and final level of basic education and, therefore, determines the career path one will follow as they mature into adulthood. As this level is a prerequisite for any form of tertiary education we help learners in all their subjects and also offer career counselling and motivation. "
            />
          </Slider>
        </div>
      </React.Fragment>
    )
  }
}

export default StudySlider
