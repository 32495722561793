import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "popper.js"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./assets/animate.css"
import "./assets/elagent/style.css"
import "./assets/font-awesome/css/all.css"
import "./assets/main.css"
import "./assets/responsive.css"
import "./assets/simple-line-icon/simple-line-icons.css"
import "./assets/themify-icon/themify-icons.css"

const loader = document.querySelector("#preloader")

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide")

const hideLoader = () => loader.classList.add("loader--hide")

setTimeout(
  () =>
    // the show/hide functions are passed as props
    ReactDOM.render(
      <App hideLoader={hideLoader} showLoader={showLoader} />,
      document.getElementById("root")
    ),
  700
)
