import React, { Component } from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"

/*------ Pages-----*/

import NotFound from "./Pages/404"
import { Home } from "./Pages/Home"
import ScrollToTopRoute from "./ScrollToTopRoute"

class App extends Component {
  componentDidMount() {
    this.props.hideLoader()
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App
